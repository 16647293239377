import React from 'react'
import tw, { styled } from 'twin.macro'
import { StyledLabel, ErrorText, twContainerBase, twLabel, twInput, Outline, UppercasePlaceholder } from './Input'
import { useStateContext } from '@context/stateContext'
import { getLocaleFormString } from '../logic'
//variants avaliable : default | long
//options: string[] - required prop
const Container = styled.div`
	${twContainerBase}
	${tw`max-w-[490px] lg:max-w-full`}
			label {
		${twLabel}
	}
`
const StyledSelect = styled.select`
	${tw`w-full bg-white`}
	${twInput}
		${UppercasePlaceholder}
		${Outline}
		background-color:white !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9.46777 0.280273L9.90723 0.719727L5 5.62695L0.0927734 0.719727L0.532227 0.280273L5 4.74805L9.46777 0.280273Z' fill='black'/></svg>");
	background-repeat: no-repeat;
	background-position-x: calc(100% - 10px);
	@media screen and (min-width: 1024px) {
		background-position-x: calc(100% - 16px);
	}
	background-position-y: 50%;
	+ label::after {
		content: ${({ required }) => (required ? "'*'" : "''")}; //adding a star to label when input is required
	}
`

export const Select = ({ label, onChange, req, variant, error, options, placeholder, value, renderingCondition, ...props }) => {
	const { language } = useStateContext()
	if (!renderingCondition) return null

	options.forEach((item, index) => {
		const getLabel = () =>
			item.text
				.split(/([(), \)])/)
				.map((item) => (item.length > 1 ? getLocaleFormString(item, language) : item))
				.join('')

		item.label = getLabel()
		delete item.text
		delete item.price
	})

	return (
		<Container variant={variant}>
			<StyledSelect
				onChange={(e) => {
					onChange(e.target.value)
				}}
				value={value}
				required={!!req}
			>
				<option disabled value=''>
					{!!placeholder ? placeholder : ' '}
				</option>
				{options.map((item, index) => {
					//if the item.label starts with <s>, then I take it as a flag and I display the label with a line-through and a disabled option.
					const isDisabled = item.label.slice(0, 3) === '<s>'
					return (
						<option value={item.label} key={index} disabled={isDisabled}>
							{isDisabled
								? item.label
										.slice(3)
										.split('')
										.map((char) => char + '\u0336')
										.join('')
								: item.label}
						</option>
					)
				})}
			</StyledSelect>
			<StyledLabel htmlFor={label}>{label}</StyledLabel>
			<ErrorText>{error && error}</ErrorText>
		</Container>
	)
}
