import { useStaticQuery, graphql } from 'gatsby'

export const useFormQuery = (id) => {
	const data = useStaticQuery(graphql`
		query HeroQuery {
			allGfForm {
				edges {
					node {
						...AllGfForm
					}
				}
			}
		}
	`)

	const { node } = data.allGfForm.edges.find((item) => {
		return item.node.formId === parseInt(id, 10)
	})

	return node
}
