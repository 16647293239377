import React from 'react'
import tw from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'
const Element = tw(motion.div)`h-full w-full`
const ImageWrapper = tw(motion.div)`h-full w-full rounded-emf overflow-hidden`

const EmfImage = ({ src, className, moveDown = true, speed = 0.03, imgStyleObjectPosition = '50%', imgStyleObjectPositionVertical = '50%', width = '100%', height = '120%', altText = '' }) => {
	const [imageRevealAnimation, setImageRevealAnimation] = React.useState(false)
	const { isTablet } = useBreakpoint()
	const [scrollY, setScrollY] = React.useState(0)
	const imgRef = React.useRef()
	const [ref, isVisible] = useInView({ threshold: 0.5 })

	const revealImage = () => {
		setImageRevealAnimation(true)
	}

	React.useEffect(() => {
		if (isVisible) {
			revealImage()
		}
	}, [isVisible])

	React.useEffect(() => {
		if (!isTablet) {
			const element = imgRef.current
			const moveOnScroll = () => {
				const rect = element.getBoundingClientRect()
				const innerHeight = window.innerHeight

				setScrollY(innerHeight - rect.top)
			}

			if (isVisible && imgRef) {
				window.addEventListener('scroll', moveOnScroll)
			} else if (imgRef === false) {
				window.addEventListener('scroll', moveOnScroll)
			}
			return () => window.removeEventListener('scroll', moveOnScroll)
		}
	}, [imgRef, scrollY, isVisible, isTablet])
	return (
		<Element ref={ref} className={className}>
			<ImageWrapper ref={imgRef} variants={revealVariants} initial='init' animate={imageRevealAnimation ? 'animate' : 'init'}>
				<GatsbyImage
					imgStyle={{
						height: height,
						width: width,
						objectPosition: `${imgStyleObjectPosition} calc(${imgStyleObjectPositionVertical} ${moveDown ? '+' : '-'} ${scrollY * speed}px)`,
						transition: 'all .3s ease-in-out',
					}}
					className='object-cover w-full h-full'
					image={getImage(src)}
					alt={altText || ''}
				/>
			</ImageWrapper>
		</Element>
	)
}

const revealVariants = {
	init: {
		clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
	},
	animate: {
		clipPath: ['polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)', 'polygon(0% 0%, 100% 0%, 100% 70%, 0% 40%)', 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'],
		transition: {
			delay: 0.2,
			duration: 0.6,
			times: [0, 0.45, 1],
		},
	},
}

export default EmfImage
