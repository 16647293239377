import React from 'react'
import tw, { styled } from 'twin.macro'
import { UppercasePlaceholder, StyledLabel, ErrorText, Outline } from './Input'

export const twTextAreaContainer = tw`relative flex flex-col mb-[32px] mx-2 max-w-[490px] w-full lg:max-w-full`
export const twTextArea = tw`relative font-futuraBook h-[121px] md:h-[200px] tracking-[1px] leading-[24px] text-[16px] lg:leading-[30px] lg:text-[18px] p-3 placeholder-mediumLight rounded-[0px]`

const Container = styled.div`
	${twTextAreaContainer}
`
const StyledTextArea = styled.textarea`
	${twTextArea}
	+ label::after {
		content: ${({ req }) => (req ? "'*'" : "''")}; //adding a star to label when TextArea is required
	}
	${UppercasePlaceholder}
	${Outline}
`

export const TextArea = ({ label, onChange, req, error, renderingCondition, ...props }) => {
	if (!renderingCondition) return null
	return (
		<Container>
			<StyledTextArea onChange={onChange} req={!!req} {...props} />
			<StyledLabel htmlFor={label}>{label}</StyledLabel>
			<ErrorText>{error && error}</ErrorText>
		</Container>
	)
}
