import React from 'react'
import { motion } from 'framer-motion'
import tw, { styled } from 'twin.macro'

//variants avaliable : light | dark(default)

export const light = tw`bg-white text-primaryDark`
export const dark = tw`text-white bg-black`
export const clipPathValue = 'polygon(0 0, 100% 0%, 100% 100%, 0% 100%)'
export const twButton = tw`tracking-1 md:tracking-2 leading-5 text-sm uppercase cursor-pointer max-w-[max-content] h-[46px] flex items-center [>span]:pl-9`
export const twButtonLabel = tw`h-[46px] pl-6 pr-20 flex justify-start items-center pointer-events-none`
const StyledButton = styled(motion.button)(({ variant }) => [twButton, variant === 'light' ? light : dark])
const StyledLabel = styled.span`
	${twButtonLabel}
`

const Button = ({ className, label, onClick, type, variant = 'dark', ...props }) => {
	return (
		<StyledButton
			variant={variant}
			className={`btn-clip ${className}`}
			onClick={onClick}
			type={type}
			whileHover={{
				clipPath: clipPathValue,
			}}
			{...props}
		>
			<StyledLabel>{label}</StyledLabel>
		</StyledButton>
	)
}

export default Button
