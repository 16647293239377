import React from 'react'

const CloseIcon = ({ onClick, className, black }) => (
	<div onClick={onClick} className={className}>
		<svg width='23' height='23' viewBox='0 0 23 23' fill={black ? 'black' : 'white'} xmlns='http://www.w3.org/2000/svg'>
			<path d='M1 1L21.5 21.5' stroke={black ? 'black' : 'white'} strokeWidth='3' />
			<path d='M22 1L1.5 21.5' stroke={black ? 'black' : 'white'} strokeWidth='3' />
		</svg>
	</div>
)
export default CloseIcon
