//Reducing returned json into one object with keys converted to only lowercase words without spaces or dashes.
export const getInitialValues = (fields) => {
	return fields.reduce((obj, item) => {
		return {
			...obj,
			[getFormName(item.id, item.type)]: getDefaultValueForField(item),
		}
	}, {})
}

export const valuePrefix = 'input_'
export const getFormName = (id, type) => (type === 'consent' ? valuePrefix + id.toString() + '_1' : valuePrefix + id.toString())

const getDefaultValueForField = (item) => {
	if (item.type === 'consent') {
		return false
	}
	if (item.type === 'date') {
		return getDateValue(item)
	}
	return item.defaultValue
}

const getDateValue = (item) => {
	//Date.parse returns a timestamp in milliseconds if the string is a valid date. Otherwise, it returns NaN.
	if (!!Date.parse(item.defaultValue)) {
		return new Date(item.defaultValue).toISOString()
	}
	//no default value set/not a valid date format
	return ''
}
