import { motion } from 'framer-motion'
import React from 'react'
import tw, { styled } from 'twin.macro'
import { useInView } from 'react-intersection-observer'

export const stripesHeight = {
	mobile: '220px',
	md: '300px',
	xl: '450px',
}
const Wrap = styled(motion.div)`
	${tw`absolute left-0 right-0 w-full pointer-events-none`}
	height: ${stripesHeight.mobile};
	@media screen and (min-width: 768px) {
		height: ${stripesHeight.md};
	}
	@media screen and (min-width: 1280px) {
		height: ${stripesHeight.xl};
	}
`

const BlueStripe = tw(motion.div)`absolute w-full bottom-14 md:bottom-12 xl:bottom-16 h-full flex`
const BlueStraight = styled(motion.div)`
	${tw`w-1/2 bg-primaryDark`}
	clip-path: polygon(0 85%, 101% 85%, 101% 100%, 0 100%);
	@media screen and (min-width: 768px) {
		clip-path: polygon(0 90%, 101% 90%, 101% 100%, 0 100%);
	}
	@-moz-document url-prefix() {
		transform: ${({ firefoxstripesfix }) => (firefoxstripesfix === 'true' ? 'translateZ(0.01px)' : 'undefined')};
	}
`
const BlueRectangle = styled(motion.div)`
	${tw`w-1/2 bg-primaryDark`}
	clip-path: polygon(0 85%, 100% 10%, 100% 50%, 0% 100%);
	@media screen and (min-width: 768px) {
		clip-path: polygon(0 90%, 100% 10%, 100% 40%, 0% 100%);
	}
`

const GreyStripe = tw(motion.div)`absolute w-full bottom-0 h-full flex`
const GreyStraight = styled(motion.div)`
	${tw`w-1/2 bg-greyStripe`}
	clip-path: polygon(0 85%, 101% 85%, 101% 100%, 0 100%);
	@media screen and (min-width: 768px) {
		clip-path: polygon(0 90%, 101% 90%, 101% 100%, 0 100%);
	}
	@-moz-document url-prefix() {
		transform: ${({ firefoxstripesfix }) => (firefoxstripesfix === 'true' ? 'translateZ(0.01px)' : 'undefined')};
	}
`
const GreyRectangle = styled(motion.div)`
	${tw`w-1/2 bg-greyStripe`}
	clip-path: polygon(0 85%, 100% 63%, 100% 100%, 0 100%);
	@media screen and (min-width: 768px) {
		clip-path: polygon(0 90%, 100% 65%, 100% 100%, 0 100%);
	}
`

const initial = {
	clipPath: 'polygon(0 0, 0 0, 0 100%, 0 100%)',
}
const animate = {
	clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
}
const transitionBlue = {
	duration: 0.5,
}
const transitionGrey = {
	duration: 0.5,
	delay: 0.3,
}

const Stripes = ({ layout, atFront = false, className = '', customTransition }) => {
	const [stripesAnimation, setStripesAnimation] = React.useState(false)

	const [ref, isVisible] = useInView({ threshold: 0.5 })

	const revealImage = () => {
		setStripesAnimation(true)
	}

	React.useEffect(() => {
		if (isVisible) {
			revealImage()
		}
	}, [isVisible])
	return (
		<Wrap ref={ref} className={`${layout === 'rotated' ? 'rotate-180' : ''} ${atFront ? 'z-30' : 'z-0'} ${className}`}>
			<BlueStripe initial={initial} animate={stripesAnimation ? animate : initial} transition={customTransition?.transitionBlue ?? transitionBlue}>
				<BlueStraight firefoxstripesfix={`${layout === 'rotated'}`}></BlueStraight>
				<BlueRectangle></BlueRectangle>
			</BlueStripe>
			<GreyStripe initial={initial} animate={stripesAnimation ? animate : initial} transition={customTransition?.transitionGrey ?? transitionGrey}>
				<GreyStraight firefoxstripesfix={`${layout === 'rotated'}`}></GreyStraight>
				<GreyRectangle></GreyRectangle>
			</GreyStripe>
		</Wrap>
	)
}

export default Stripes
