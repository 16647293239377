import React from 'react'
import tw, { styled } from 'twin.macro'
import { motion, AnimatePresence } from 'framer-motion'
import CloseIcon from '../CloseIcon'
import { useFormQuery } from '@hooks/useForm'
import parse from 'html-react-parser'
import { useStateContext } from '@context/stateContext'
import { getLocaleFormString } from './logic'
import { useInView } from 'react-intersection-observer'
// import Form from '@elements/form/Form'

export const dropIn = {
	hidden: {
		y: '-100vh',
	},
	visible: {
		y: 0,
		transition: {
			type: 'tween',
			ease: 'easeInOut',
			duration: 0.4,
		},
	},
	exit: {
		y: '-100vh',
		transition: {
			type: 'tween',
			ease: 'easeInOut',
			duration: 0.4,
		},
	},
}
const modalVariants = {
	init: {
		opacity: 0,
		visibility: 'hidden',
	},
	animate: {
		visibility: 'visible',
		opacity: 1,
		transition: {
			duration: 0.3,
		},
	},
	exit: {
		opacity: 0,
	},
}
const StyledHeadline = tw.h2`font-futuraExtra break-words text-black font-extrabold tracking-1 md:tracking-3 uppercase text-left max-w-[fit-content] lg:w-max lg:max-w-[1000px]
text-[30px] leading-[40px]
xs:text-[38px] xs:leading-[45px]
md:text-[50px] md:leading-[50px]
lg:text-[70px] lg:leading-[70px]
xl:text-[100px] xl:leading-[100px] pt-14 pb-[38px] lg:pb-[94px] lg:pt-20 mx-2`

const StyledCloseIcon = tw(CloseIcon)`absolute right-0 md:right-2 xl:right-4 top-2 z-[100] uppercase text-[35px] leading-[30px] text-black cursor-pointer px-3.5 py-4`
const ModalContainer = styled(motion.div)`
	${tw`relative top-0 w-full max-w-max bg-gray-50 rounded-emf`};
`

const Backdrop = styled(motion.div)`
	${tw`fixed top-0 left-0 h-screen w-full bg-[#000000e1] flex justify-center items-center py-3`};
`
const Spacer = tw.div`relative py-3.5`
const ModalContent = tw.div`relative overflow-auto max-h-[85vh] px-5 lg:px-7 xl:px-[104px] w-full`

const Modal = ({ handleClose, formId, locale, modalOpen }) => {
	const formData = useFormQuery(formId) //pass formId
	const { language } = useStateContext()

	const [ref, isVisible] = useInView({ threshold: 0.4, triggerOnce: true })
	const LoadForm = () => {
		const Form = React.lazy(() => import('./Form'))
		return <Form formId={formData.formId} apiURL={formData.apiURL} fields={formData.formFields} locale={locale} closeModal={handleClose} buttonLabel={formData.button.text} />
	}
	const isSSR = typeof window === 'undefined'
	return (
		<motion.div variants={modalVariants} initial='init' animate={modalOpen ? 'animate' : 'init'} exit='exit' tw='inset-0 fixed h-screen w-full items-center flex justify-center z-[9999]' ref={ref}>
			<AnimatePresence>
				{modalOpen && isVisible && (
					<>
						<Backdrop onClick={handleClose} />
						<ModalContainer variants={dropIn} initial='hidden' animate='visible' exit='exit'>
							<Spacer className='hidden lg:block' />
							<StyledCloseIcon onClick={handleClose} black />
							<ModalContent>
								{!formData && <p className='block w-full max-w-full py-20 text-center text-black'>Form does not exist.</p>}
								{formData && !isSSR && (
									<React.Suspense fallback={null}>
										<StyledHeadline>{getLocaleFormString(parse(formData.slug), language)}</StyledHeadline>
										<LoadForm />
									</React.Suspense>
								)}
							</ModalContent>
							<Spacer />
						</ModalContainer>
					</>
				)}
			</AnimatePresence>
		</motion.div>
	)
}

export default Modal
