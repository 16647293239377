import { Link } from 'gatsby'
import React from 'react'
import { motion } from 'framer-motion'
import tw, { styled } from 'twin.macro'
import { light, dark } from './Button'

//variants avaliable : light | dark(default)
const LinkBase = tw`pr-14 xs:pr-20 py-3.5 tracking-1 md:tracking-2 leading-5 text-sm uppercase cursor-pointer pl-5 xs:pl-9 inline-flex`
const StyledLink = styled(motion(Link))(({ variant }) => [LinkBase, variant === 'light' ? light : dark])
const StyledExternalLink = styled(motion.a)(({ variant }) => [LinkBase, variant === 'light' ? light : dark])

const LinkButton = ({ className, label, href, onClick, variant = 'dark', target }) => {
	const hoverValue = 'polygon(0 0, 85% 0%, 85% 100%, 0% 100%)'
	const getLinkType = () => {
		if (target === '_blank') {
			return (
				<StyledExternalLink
					whileHover={{
						clipPath: hoverValue,
					}}
					href={href ?? '/'}
					variant={variant}
					className={`btn-clip ${!href && 'pointer-events-none'}`}
					target={target}
				>
					{label}
				</StyledExternalLink>
			)
		} else {
			return (
				<StyledLink
					whileHover={{
						clipPath: hoverValue,
					}}
					to={href ?? '/'}
					variant={variant}
					className={`btn-clip ${!href && 'pointer-events-none'}`}
				>
					{label}
				</StyledLink>
			)
		}
	}
	return (
		<motion.div className={className} onClick={onClick}>
			{getLinkType()}
		</motion.div>
	)
}

export default LinkButton
