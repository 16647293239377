import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { ErrorText } from './Input'

//variants avaliable : default | long

const primaryDark = '#003899'
export const twCheckboxContainer = tw`relative flex flex-col mb-[40px] mx-2 items-start w-full`
export const cssCheckbox = css`
	position: relative;
	height: 25px;
	width: 24px;
	min-height: 25px;
	min-width: 24px;
	cursor: pointer;
	border: 1px solid black;
	border-radius: 0px;
	-webkit-appearance: none;
	background-color: white;
	/* hover state */
	:not(:disabled):hover {
		border-color: ${primaryDark};
	}
	/* active state */
	:active:not(:disabled) {
		border-color: ${primaryDark};
	}
	/* focus state */
	:focus {
		outline: none;
	}
	/* input checked border color */
	:checked {
		border-color: ${primaryDark};
		background-color: ${primaryDark};
	}
	/* checkbox checked */
	:checked:before {
		content: '';
		display: block;
		width: 8px;
		height: 16px;
		border: solid #fff;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		margin-left: 7px;
		margin-top: 2px;
	}
	/* disabled input */
	:disabled {
		opacity: 0.6;
		box-shadow: none;
		background: rgba(0, 0, 0, 0.1);
		box-shadow: none;
	}
`
export const twCheckboxLabel = tw`relative font-futuraBook tracking-[1px] leading-[24px] text-[16px] lg:leading-[30px] lg:text-[23px] self-start ml-[14px]`
export const cssCheckboxLabel = css`
	/* unvisited link */
	a {
		text-decoration: underline;
	}
	a:link {
		color: #003899;
	}

	/* visited link */
	a:visited {
		color: #0054a1;
	}

	/* mouse over link */
	a:hover {
		color: #0054a1;
		text-decoration: none;
	}

	/* selected link */
	a:active {
		color: #0054a1;
	}
`
const Container = styled.div`
	${twCheckboxContainer}
`

const StyledCheckbox = styled.input`
	+ label::after {
		content: ${({ req }) => (req ? "'*'" : "''")}; //adding a star to label when input is required
	}
	${cssCheckbox}
`
const StyledLabel = styled.label`
	${twCheckboxLabel}
	${cssCheckboxLabel}
`
export const Checkbox = ({ label, checkboxLabel, onChange, req, variant, error, labelClick, renderingCondition, ...props }) => {
	if (!renderingCondition) return null
	const getHref = (link) => link.match(/href="([^"]*)/)[1]
	const noPropagation = (e) => e.stopPropagation()
	return (
		<Container variant={variant}>
			<div tw='relative flex items-center cursor-pointer' onClick={onChange}>
				<StyledCheckbox type='checkbox' onChange={onChange} req={!!req} required={!!req} {...props} />
				<StyledLabel htmlFor={label} tw='cursor-pointer select-none'>
					{/href="([^"]*)/.test(checkboxLabel) ? (
						<>
							{checkboxLabel.split('<a')[0]}
							<a href={getHref(checkboxLabel)} target='_blank' onClick={noPropagation}>
								{checkboxLabel.split('>')[1].split('<')[0]}
							</a>
							{checkboxLabel.split('</a>')[1]}
						</>
					) : (
						checkboxLabel
					)}
				</StyledLabel>
			</div>
			<ErrorText>{error && error}</ErrorText>
		</Container>
	)
}
