export const strings = {
	de: {
		nextRace: 'Nächstes Rennen',
		days: 'Tage',
		hours: 'Stunden',
		minutes: 'Minuten',
		seconds: 'Sekunden',
		raceDetails: 'Mehr zum Rennen',
		showMore: 'Mehr anzeigen',
		showLess: 'Weniger anzeigen',
		readMore: 'Mehr erfahren',
		all: 'Alle',
		teamMembers: 'Team Mitglie&shy;der',
		standings: 'Rangliste',
		raceCalendar: 'Rennkalender',
		press: 'Presse&shy;Meldun&shy;gen',
		singlePress: 'Presse&shy;Meldung',
		backToPress: 'Zurück zu Pressemeldungen',
		backToPressPhotos: 'Zurück zu Pressefotos',
		pressPhotos: 'Presse&shy;Fotos',
		allStandings: 'Alle Ergebnisse:',
		teamFormButton: 'Autogramme? Immer gerne!',
		loading: 'Laden',
		season: 'Saison',
		events: 'Erlebnisse',
		legalContact: 'Rechtliches und Kontakt',
		legalNotes: 'Rechtliche Hinweise',
		imprint: 'Impressum',
		dataProtection: 'Datenschutz',
		toLivestream: 'Zum livestream',
		team: {
			administration: 'Administration',
			drivers: 'Piloten',
			logistics: 'Logistiker',
			mechanics: 'Mechaniker',
			management: 'Management',
			engineers: 'Ingenieure',
		},
		form: {
			contact: 'Kontakt',
			name: 'Nachname',
			firstName: 'Vorname',
			email: 'Email-Adresse',
			message: 'Nachricht',
			consentLabel: 'Hiermit stimme ich den <a href="/datenschutz/">Datenschutzbestimmungen</a> zu.',
			submit: 'Abschicken',
			submit2: 'Anmelden',
			requiredMessage: '*Pflichtfelder',
			consent: 'Consent',
			autographCards: 'AUTOGRAMM-KARTEN',
			street: 'Strasse und Hausnr.',
			street2: 'Strasse und Hausnummer',
			city: 'STADT',
			city2: 'ORT',
			zip: 'PLZ',
			country: 'LAND',
			vip: 'VIP-TICKETS',
			race: 'rennen',
			birthday: 'Geburtsdatum',
			phone: 'TELEFONNUMMER',
			arrivalDate: 'ANKUNFTSDATUM',
			departureDate: 'ABREISEDATUM',
			carsNumber: 'MIT WIE VIELEN AUTOS REISEN SIE AN?',
			employee: 'Ich bin Mitarbeiter der Emil Frey Gruppe.',
			employeeNumber: 'MITARBEITERNUMMER',
			factoryTour: 'WORKSHOP-TOUREN',
			simulatorTraining: 'SIMULATOR-TRAINING',
			coaching: 'COACHING',
			gymTraining: 'GYM-TRAINING',
			private: 'Privat',
			presse: 'Presse',
			german: 'Deutsch',
			english: 'English',
			incl: 'inkl.',
			vat: 'Mwst',
			january: 'Januar',
			february: 'Februar',
			march: 'März',
			april: 'April',
			mail: 'Mai',
			june: 'Juni',
			july: 'Juli',
			august: 'August',
			september: 'September',
			october: 'Oktober',
			november: 'November',
			december: 'Dezember',
		},
	},
	en: {
		nextRace: 'Next race',
		days: 'days',
		hours: 'hours',
		minutes: 'minutes',
		seconds: 'seconds',
		raceDetails: 'Race details',
		showMore: 'Show more',
		showLess: 'Show less',
		readMore: 'Read more',
		all: 'All',
		teamMembers: 'Team members',
		standings: 'Standings',
		raceCalendar: 'Race calendar',
		press: 'Press releases',
		singlePress: 'Press release',
		backToPress: 'Back to press',
		backToPressPhotos: 'Back to press photos',
		pressPhotos: 'Press Photos',
		allStandings: 'All standings:',
		teamFormButton: 'Autographs? Always with pleasure!',
		loading: 'Loading',
		season: 'Season',
		events: 'Events',
		legalContact: 'Legal and contact',
		legalNotes: 'Legal notes',
		imprint: 'Imprint',
		dataProtection: 'Data protection',
		toLivestream: 'To the livestream',
		team: {
			administration: 'Administration',
			drivers: 'Drivers',
			logistics: 'Logistics specialists',
			mechanics: 'Mechanics',
			management: 'Management',
			engineers: 'Engineers',
		},
		form: {
			contact: 'Contact',
			name: 'Name',
			firstName: 'First Name',
			email: 'Email Address',
			message: 'Message',
			consentLabel: 'I confirm that I accept the provisions in the <a href="/en/datenschutz/">privacy policy</a>.',
			submit: 'Submit',
			submit2: 'Submit',
			requiredMessage: '*Required fields',
			consent: 'Consent',
			autographCards: 'Autograph Cards',
			street: 'STREET AND HOUSENUMBER',
			street2: 'STREET AND HOUSENUMBER',
			city: 'City',
			city2: 'City',
			zip: 'Zip Code',
			country: 'Country',
			vip: 'VIP-TICKETS',
			race: 'race',
			birthday: 'birth date',
			phone: 'phone',
			arrivalDate: 'DATE OF ARRIVAL',
			departureDate: 'DATE OF DEPATURE',
			carsNumber: 'HOW MANY CARS ARE YOU TRAVELING WITH?',
			employee: 'I am an employee of the Emil Frey Group.',
			employeeNumber: 'EMPLOYEE NUMBER',
			factoryTour: 'Workshop Tour',
			simulatorTraining: 'SIMULATOR-TRAINING',
			coaching: 'COACHING',
			gymTraining: 'GYM-TRAINING',
			private: 'Private',
			presse: 'Press',
			german: 'German',
			english: 'English',
			incl: 'incl. ',
			vat: 'VAT',
			january: 'January',
			february: 'February',
			march: 'March',
			april: 'April',
			mail: 'May',
			june: 'June',
			july: 'July',
			august: 'August',
			september: 'September',
			october: 'October',
			november: 'November',
			december: 'December',
		},
	},
}
