import React from 'react'
import tw, { styled } from 'twin.macro'
import { Container, StyledLabel, ErrorText, UppercasePlaceholder, Outline } from './Input'

//variants avaliable : default | long
//options: string[] - required prop
const StyledDatePicker = styled.input`
	${tw`relative font-futuraBook h-[37px] lg:h-[46px] tracking-[1px] leading-[24px] text-[16px] lg:leading-[30px] lg:text-[18px] w-full text-left px-3 placeholder-mediumLight bg-white rounded-[0px]`}
	${UppercasePlaceholder}
	${Outline}
	-webkit-appearance: none;
	-moz-appearance: none;
	::-webkit-date-and-time-value {
		text-align: left;
		width: 100%;
	}
`
const StyledDatePickerLabel = styled(StyledLabel)`
	::after {
		content: ${({ req }) => (req ? "'*'" : "''")}; //adding a star to label when DatePicker is required
	}
`

export const Datepicker = ({ label, onChange, req, variant, error, value, renderingCondition, ...props }) => {
	if (!renderingCondition) return null

	return (
		<Container variant={variant}>
			<StyledDatePicker
				type='date'
				onChange={(e) => {
					!!e.target.value ? onChange(new Date(e.target.value).toISOString().substring(0, 10)) : onChange('')
				}}
				value={value.substring(0, 10)}
				required={!!req}
				placeholder='dd-mm-yyyy'
				min='1900-01-01'
			/>
			<StyledDatePickerLabel htmlFor={label} req={!!req}>
				{label}
			</StyledDatePickerLabel>
			<ErrorText>{error && error}</ErrorText>
		</Container>
	)
}
