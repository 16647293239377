import React from 'react'
import tw, { styled, css } from 'twin.macro'

//variants avaliable : default | long
export const twContainerBase = tw`relative flex flex-col mb-[18px] lg:mb-[32px] mx-2 w-full`
export const Container = styled.div(({ variant }) => [
	twContainerBase,
	variant === 'long' || variant === 'large' ? tw`max-w-[490px] lg:max-w-full` : tw`max-w-[490px] lg:max-w-[468px] xl:max-w-[490px] `,
])

export const ErrorText = tw.p`text-secondary uppercase mt-0.5`
export const UppercasePlaceholder = css`
	::-webkit-input-placeholder {
		text-transform: uppercase;
	}
	/* Firefox 19+ */
	::-moz-placeholder {
		text-transform: uppercase;
	}
	/* IE 10-11 */
	:-ms-input-placeholder {
		text-transform: uppercase;
	}
	/* Edge */
	::-ms-input-placeholder {
		text-transform: uppercase;
	}
	/* MODERN BROWSER */
	::placeholder {
		text-transform: uppercase;
	}
`
export const Outline = css`
	outline: 1px solid black;
	&:focus {
		outline: 2px solid #003899;
	}
`
export const twInput = tw`relative font-futuraBook h-[33px] lg:h-[44px] tracking-[1px] leading-[24px] text-[16px] lg:leading-[30px] lg:text-[18px] px-3 placeholder-mediumLight`
export const StyledInput = styled.input`
	${twInput}
	${Outline}
	+ label::after {
		content: ${({ req }) => (req ? "'*'" : "''")}; //adding a star to label when input is required
	}
	${UppercasePlaceholder}
`
export const twLabel = tw`relative font-futuraBook tracking-[1px] leading-[24px] text-[16px] lg:leading-[30px] lg:text-[27px] uppercase self-start mr-1 mb-0.5 lg:mb-[7px] order-first`
export const StyledLabel = styled.label`
	${twLabel}
`
export const getInputMode = (type) => {
	if (type === 'email') return 'email'
	if (type === 'number') return 'numeric'
	if (type === 'phone') return 'tel'
	return undefined
}

export const Input = ({ label, onChange, type, req, variant, error, renderingCondition, ...props }) => {
	if (!renderingCondition) return null

	const isNumeric = type === 'number' || type === 'phone'
	const isNumericInput = (e) => {
		return /[0-9]/.test(e.key) || ['Backspace', 'Delete'].includes(e.key)
	}
	return (
		<Container variant={variant}>
			<StyledInput
				onChange={onChange}
				req={!!req}
				required={!!req}
				variant={variant}
				type={type === 'tel' ? 'tel' : type}
				pattern={isNumeric ? '[0-9]*' : undefined}
				inputMode={getInputMode()}
				onKeyDown={isNumeric ? (e) => !isNumericInput(e) && e.preventDefault() : undefined}
				{...props}
			/>
			<StyledLabel htmlFor={label}>{label}</StyledLabel>
			<ErrorText>{error && error}</ErrorText>
		</Container>
	)
}
