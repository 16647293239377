import React from 'react'
import { getFormName, valuePrefix } from './getInitialValues'
import { useStateContext } from '@context/stateContext'
import { strings } from '../../../../static/strings'
import { Input, TextArea, Checkbox, Select, Datepicker } from '@elements/form/controls'

export const getLocaleFormString = (string, language) => {
	const result = Object.keys(strings['de'].form).find((key) => strings['de'].form[key].toUpperCase() === string.toUpperCase())
	if (language === 'en') {
		return strings[language].form[result] ? strings[language].form[result] : string
	}
	return string
}

export const MappedControls = ({ fields, onChangeFunc, errors, values, setFieldValue }) => {
	const { language } = useStateContext()

	return fields.map((item, index) => {
		const { id, label, checkboxLabel, isRequired, placeholder, size, conditionalLogic } = item

		const formikName = getFormName(id, item.type)
		const localeLabel = getLocaleFormString(label, language)
		const hasConditionalRendering = !!JSON.parse(conditionalLogic)
		const checkRenderingCondition = () => {
			if (hasConditionalRendering) {
				return values[valuePrefix + JSON.parse(conditionalLogic).rules[0].fieldId + '_1']
			}
		}

		switch (item.type) {
			case 'text':
			case 'email':
			case 'number':
			case 'phone':
				return (
					<Input
						key={label + index}
						label={localeLabel}
						type={item.type}
						req={isRequired}
						placeholder={placeholder}
						variant={size}
						onChange={onChangeFunc(formikName)}
						value={values[formikName]}
						error={errors[formikName]}
						renderingCondition={hasConditionalRendering ? checkRenderingCondition() : true}
					/>
				)
			case 'textarea':
				return (
					<TextArea
						key={label + index}
						label={localeLabel}
						req={isRequired}
						placeholder={placeholder}
						variant={size}
						onChange={onChangeFunc(formikName)}
						value={values[formikName]}
						error={errors[formikName]}
						renderingCondition={hasConditionalRendering ? checkRenderingCondition() : true}
					/>
				)
			case 'select':
				return (
					<Select
						key={label + index}
						label={localeLabel}
						req={isRequired}
						placeholder={placeholder}
						onChange={onChangeFunc(formikName)}
						options={JSON.parse(item.choices.split(/[/]+/))}
						variant={size}
						value={values[formikName]}
						error={errors[formikName]}
						renderingCondition={hasConditionalRendering ? checkRenderingCondition() : true}
					/>
				)
			case 'consent':
				return (
					<Checkbox
						key={label + index}
						label={localeLabel}
						checkboxLabel={getLocaleFormString(checkboxLabel, language)}
						req={isRequired}
						onChange={() => {
							setFieldValue(formikName, !values[formikName])
						}}
						value={true}
						name={formikName}
						checked={values[formikName]}
						error={errors[formikName]}
						renderingCondition={hasConditionalRendering ? checkRenderingCondition() : true}
					/>
				)
			case 'date':
				return (
					<Datepicker
						key={label + index}
						label={localeLabel}
						req={isRequired}
						onChange={(val) => setFieldValue(formikName, val)}
						value={values[formikName]}
						error={errors[formikName]}
						renderingCondition={hasConditionalRendering ? checkRenderingCondition() : true}
					/>
				)
			default:
				return (
					<Input
						key={label + index}
						type={item.type}
						label={localeLabel}
						req={isRequired}
						placeholder={placeholder}
						variant={size}
						onChange={onChangeFunc(formikName)}
						value={values[formikName]}
						error={errors[formikName]}
						renderingCondition={!hasConditionalRendering ?? checkRenderingCondition()}
					/>
				)
		}
	})
}
